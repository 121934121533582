<template>
  <div v-loading="loaders.contentSection" class="m-0 px-3 pt-4 pb-0 pr-2">
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="m-0">{{ sectionName }}</h1>
      <div class="d-flex justify-content-end">
        <router-link class="ml-2" :to="{ name: 'DynamicContentSectionCategory', params: {sectionId, sectionName}}">
          <el-button
            size="md"
            class="white-outline-button title-btn-padding button-font button-hover-effect" type="secondary">
            Categories
          </el-button>
        </router-link>
        <router-link class="ml-2" :to="{ name: 'DynamicContentSectionSubCategory', params: {sectionId: sectionId}}">
          <el-button
            size="md"
            class="white-outline-button title-btn-padding button-font button-hover-effect" type="secondary">
            Sub Categories
          </el-button>
        </router-link>
        <router-link
          class="ml-2 "
          :to="{ name: 'StoreDynamicContentSectionItem', params: {sectionId, sectionName}}">
          <el-button class="btn-secondary-action title-btn-padding button-font button-hover-effect">Add New</el-button>
        </router-link>
      </div>
    </div>
    <div class="mt-4 cardStyle">
      <div id="select-option-container" class="mt-4 ml-4" :class="{invisible: !selectedRows.length}">
        <base-button class="mt-0 mb-3 pl-5 pr-5 red-outline-button"
                     @click="promptMultiRemove"
                     :loading="loaders.checkboxButton.Delete"
                     :disabled="loaders.contentSection"
        >Delete
        </base-button>
        <base-button class="mt-0 mb-3 pl-5 pr-5 green-outline-button"
                     @click="handleActivate"
                     :loading="loaders.checkboxButton.Activate"
                     :disabled="loaders.contentSection"
        >Activate
        </base-button>
        <base-button class="mt-0 mb-3 pl-5 pr-5 black-outline-button"
                     @click="handleDeactivate"
                     :loading="loaders.checkboxButton.DeActive"
                     :disabled="loaders.contentSection"
        >Deactivate
        </base-button>
      </div>
      <el-table-draggable
        handle=".handle"
        @drop="updateSortList"
      >
        <el-table
          class="table-responsive table-flush checklist-items-table checklist-table"
          header-row-class-name="thead-light"
          width="100%"
          ref="dynamicSectionItemListingTableRef"
          :data="response.dynamicSectionListingItems"
          :row-class-name="row"
          @selection-change="sectionListMultipleSelect"
        >
          <el-table-column align="left" :class-name="btnDraggable" width="50">
            <template slot-scope="scope">
              <div class="handle" v-if="scope.row.id !==''">
                <img src="/img/icons/buttons/handle.svg" alt="+">
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="selected" type="selection" min-width="130px">
          </el-table-column>
          <el-table-column label="Category" min-width="130px">
            <template slot-scope="scope">
              <div class="trim-text-overflow">
                {{ scope.row.dynamic_section_category ? scope.row.dynamic_section_category.name : '' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Sub Category" min-width="130px">
            <template slot-scope="scope">
              <div class="trim-text-overflow">
                {{ scope.row.dynamic_section_sub_category ? scope.row.dynamic_section_sub_category.name : '' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="NAME" prop="name" min-width="130px">
            <template slot-scope="scope">
              <div class="trim-text-overflow">{{ scope.row.name }}</div>
            </template>
          </el-table-column>
          <el-table-column label="STATUS" prop="status" min-width="130px">
            <template slot-scope="scope">
              <span :class="['status',getStatus(scope.row.status)]">
                <div class="d-inline-block indicator" :class="getStatus(scope.row.status)"></div>
                {{ getStatus(scope.row.status) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="ACTIONS">
            <div slot-scope="{ $index, row }" class="d-flex">
              <router-link :to="{name: 'ShowDynamicContentSection', params: {id: row.id}}">
                <img class="eye-icon mr-2" src="/img/eye.svg" alt="">
              </router-link>
              <router-link
                :to="{name: 'EditDynamicContentSectionItem', params: {itemId: row.id, sectionId, sectionName}}">
                <img class="pointer mr-2" src="/img/icons/buttons/edit.svg" alt="">
              </router-link>
              <img
                class="pointer"
                @click="handleDelete($index, row)"
                src="/img/icons/buttons/delete.svg"
                alt="Delete"
              />
            </div>
          </el-table-column>
        </el-table>
      </el-table-draggable>
    </div>
    <div class="modals">
      <remove-modal
        :loader="loaders.removeModal"
        :modal-text="removeModalText"
        :removeId="removeId"
        @onRemove="removeDynamicSectionItem"
        @onRemoveMultiple="removeMultipleDynamicSectionItems"
      />
    </div>
  </div>
</template>

<script>
import {Table, TableColumn} from "element-ui";
import ElTableDraggable from 'element-ui-el-table-draggable';

const RemoveModal = () => import("@/components/Modals/RemoveModal.vue");

export default {
  name: "DynamicContentSection",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    RemoveModal,
    ElTableDraggable,
  },
  data() {
    return {
      loaders: {
        contentSection: false,
        removeModal: false,
        checkboxButton: {
          Activate: false,
          DeActive: false,
          Delete: false,
        },
      },
      removeModalText: '',
      removeId: '',
      selectedRows: [],
      btnDraggable: 'btnDraggable',
      response: {
        dynamicSectionListingItems: [],
      },
      dynamicSectionName: ''
    };
  },
  mounted() {
    this.fetchDynamicSectionListingItems()
  },
  methods: {
    /**
     * Fetch All the listing items of this dynamic content section
     */
    fetchDynamicSectionListingItems() {
      let vm = this
      vm.loaders.contentSection = true
      const payload = {
        dynamicSectionId: vm.sectionId
      }
      vm.$store.dispatch('dynamicContentSectionModule/_getDynamicContentSectionListing', payload)
        .then(response => {
          vm.response.dynamicSectionListingItems = response.data.data.dynamicSectionListingItems
          vm.dynamicSectionName = response.data.data.sectionName
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Dynamic Content Section List',
            message: message
          });
        })
        .finally(() => {
          vm.loaders.contentSection = false
        })
    },

    /**
     * Handle the active button event
     */
    handleActivate() {
      this.statusUpdateRequest(this.extractSelectedIds, true)
    },

    row({row, rowIndex}) {
      return "handle";
    },

    /**
     * Handle the deactivate button event
     */
    handleDeactivate() {
      this.statusUpdateRequest(this.extractSelectedIds, false)
    },

    /**
     * Get status as a string
     * @param status
     * @returns {string}
     */
    getStatus(status) {
      return status === 0 ? 'Inactive' : 'Active'
    },

    /**
     * Handle event for selecting multiple rows from table
     * @param selectedRows
     */
    sectionListMultipleSelect(selectedRows) {
      this.selectedRows = selectedRows
    },

    /**
     * Update the status for selected row(s) from table
     * @param ids
     * @param status
     */
    statusUpdateRequest(ids, status) {
      let vm = this
      vm.loaders.contentSection = true
      const payload = {
        ids: ids,
        status: status ? 1 : 0,
      }

      vm.$store.dispatch('dynamicContentSectionModule/_updateStatusDynamicSectionListingItem', payload)
        .then(response => {
          vm.removeId = []
          vm.$notify.success({
            title: 'Success',
            message: 'Status updated successfully.'
          })
          vm.fetchDynamicSectionListingItems()
        })
        .catch((error) => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Update Status Section Item',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.contentSection = false
        })
    },

    // This function accept the arguments and returns new order key
    assignNewOrderKey(oldOrderKey, prev, curr, next) {
      let newOrderKey
      if (prev === '') {
        if (curr < next || next === '') {
          newOrderKey = oldOrderKey
        } else {
          newOrderKey = next
        }
      } else if (next === '') {
        if (curr > prev || prev === '') {
          newOrderKey = oldOrderKey
        } else {
          newOrderKey = prev
        }
      } else if (curr < prev && curr < next) {
        newOrderKey = prev
      } else if (curr > prev && curr > next) {
        newOrderKey = next
      } else if (curr > prev && curr < next) {
        newOrderKey = oldOrderKey
      }
      return newOrderKey
    },

    // This function returns counts of 0 order keys
    orderCheck(list) {
      let orderCheckCounter = 0
      for (const item of list) {
        if (item.order_key === 0) {
          orderCheckCounter++
          if (orderCheckCounter > 1) {
            break
          }
        }
      }
      return orderCheckCounter;
    },

    //This function returns payload based on defined conditions
    assignPayload(orderCheckCounter, newOrderKey, oldOrderKey, event, duplicateOrderKey) {
      let payload
      if (orderCheckCounter > 1 || duplicateOrderKey == true) {
        let orderKeyArray = []
        let order = 1;
        for (const item of event.list) {
          orderKeyArray.push({id: item.id, order_key: order}) // Array having order set from Event
          order++
        }
        payload = {orderKeyArray}
      } else {
        payload = {id: event.targetObject.id, newOrderKey, oldOrderKey}
      }
      return payload
    },

    /**
     * Updates the Order of the table list
     * @param event
     * @returns {Promise<void>}
     */
    async updateSortList(event) {
      let vm = this
      let oldOrderKey = event.targetObject.order_key
      let newOrderKey
      let currIndex = event.list.findIndex(item => item.id === event.targetObject.id)
      let curr = event.list.find(item => item.id === event.targetObject.id).order_key
      let prev = (event.list[currIndex - 1]) ? event.list[currIndex - 1].order_key : ''
      let next = (event.list[currIndex + 1]) ? event.list[currIndex + 1].order_key : ''

      // This function assigns new order key based on defined conditions
      newOrderKey = this.assignNewOrderKey(oldOrderKey, prev, curr, next)

      let orderCheckCounter = 0 // Counter to check number of 0's in order

      // Pass list and counter to get counts of 0 order key
      orderCheckCounter = this.orderCheck(event.list)

      const duplicateOrderKey = this.checkDuplicateOrderKey(event.list)

      // Check if item is not drag to other position
      if (newOrderKey === oldOrderKey && orderCheckCounter < 2 && orderCheckCounter > 0) {
        return
      }

      let payload // Initializing payload

      //This function assigns the payload based on defined conditions
      payload = this.assignPayload(orderCheckCounter, newOrderKey, oldOrderKey, event, duplicateOrderKey)

      const dispatchPayload = {
        contentMenu: 'dynamic-content-section/section',
        payload
      }

      try {
        const response = await vm.$store.dispatch('_updateSortList', dispatchPayload); // Dispatch Api call to update sort list
        vm.response.diets = response.data.data
      } catch (error) {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Ordering Dynamic Section List Items',
          message: message
        });
      }
    },

    /**
     * Check and return if array has duplicate order key
     * @param list
     * @returns {boolean}
     */
    checkDuplicateOrderKey(list) {
      let orderKeyArray = []
      for (const item of list) {
        if (orderKeyArray.includes(item.order_key)) {
          return true
        } else {
          orderKeyArray.push(item.order_key)
        }
      }
      return false
    },

    /**
     * Handle delete button event
     * @param index
     * @param row
     */
    handleDelete(index, row) {
      this.promptRemove(row)
    },

    /**
     * Prompt the Remove modal for a single item
     * @param row
     */
    promptRemove(row) {
      this.$store.commit("showRemoveModal")
      this.removeModalText = 'You want to remove this ' + this.sectionName + '.'
      this.removeId = row.id
    },

    /**
     * Prompt the Remove modal for multiple items
     */
    promptMultiRemove() {
      this.$store.commit("showRemoveModal")
      this.removeModalText = 'You want to remove these ' + this.sectionName + '.'
      this.removeId = this.extractSelectedIds
    },

    /**
     * Handle remove single item event
     * @param id
     */
    removeDynamicSectionItem(id) {
      this.deleteRequest(id)
    },

    /**
     * Handle remove multiple items event
     */
    removeMultipleDynamicSectionItems() {
      this.deleteRequest(this.removeId)
    },

    /**
     * Delete content section items from table
     * @param data
     */
    deleteRequest(data) {
      let vm = this
      vm.loaders.contentSection = true
      vm.loaders.checkboxButton.Delete = true
      const payload = {ids: Array.isArray(data) ? data : [data]};

      vm.$store.dispatch('dynamicContentSectionModule/_deleteDynamicSectionListingItem', payload)
        .then(response => {
          vm.removeId = []
          vm.fetchDynamicSectionListingItems()
          vm.$store.commit("hideRemoveModal")
          vm.$notify.success({
            title: 'Success',
            message: vm.sectionName + ' items deleted successfully.'
          })
        })
        .catch((error) => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Delete Section Item',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.checkboxButton.Delete = false
          vm.loaders.contentSection = false
        })
    },
  },
  computed: {
    /**
     * Extract the ids from selected rows from table
     * @returns {*[]}
     */
    extractSelectedIds() {
      let selectedIds = []
      for (const selectedRow of this.selectedRows) {
        selectedIds.push(selectedRow.id)
      }
      return selectedIds
    },
    sectionId() {
      return this.$route.params.id ?? -1
    },
    sectionName: {
      get() {
        return this.$route.params.name ?? this.dynamicSectionName
      },
      set(sectionName) {
        this.dynamicSectionName = sectionName
      }
    }
  },
  watch: {
    sectionId(newVal) {
      this.fetchDynamicSectionListingItems()
    }
  },
}
</script>

<style scoped>
.handle {
  cursor: grabbing;
}

.btnDraggable {
  padding-top: 0px !important;
  padding-left: 0px !important;
  padding-bottom: 0px !important;
  cursor: grabbing;
  margin-left: 0px;
}

.status .Active, .status .Inactive {
  position: relative;
  margin-left: 15px !important;
}

.indicator {
  height: 7px;
}

.status .Active:before, .status .Inactive:before {
  content: "";
  position: absolute;
  left: -15px !important;
  height: 7px;
  width: 7px;
  bottom: 1px;
  border-radius: 100%;
}

.status .Active:before {
  background-color: #94B527 !important;
}

.status .Inactive:before {
  background-color: #BDC4CC !important;
}

.cardStyle {
  background-color: white;
  border-radius: 6px;
  border: 1px solid #00000000;;
}
</style>
